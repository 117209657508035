<template>
    <div class="report_box">
        <h3>提现进度</h3>
        <div class="progress"><span class="name">{{ project_title }}</span> <span class="price">{{amount/100}}元</span></div>

        <div class="progress_box" v-if="datailDate.length > 0">
            <div ref="step_1" class="item" :class="{'ok': datailDate[0].is_done, 'err':  datailDate[0].is_error}">
                <p class="title" style="color: #009BFF;">{{datailDate[0].title}}</p>
                <p class="subtitle" v-if="datailDate[0].time">{{ datailDate[0].time | format }}</p>
            </div>

            <div ref="step_2" class="item" :class="{'ok': datailDate[1].is_done, 'err': datailDate[1].is_error}">
                <p class="title" :class="{'ok_txt': datailDate[1].is_done, 'err_txt': datailDate[1].is_error}" style="height: 24px">
                    {{datailDate[1].title}}
                    <button v-if="datailDate[1].is_error" @click="editInfo">再次提现</button>
                </p>
                <p class="subtitle" v-if="datailDate[1].time">{{ datailDate[1].time | format }}</p>
                <template v-for="(item, index) in datailDate[1].tips">
                    <p class="dec" v-if="datailDate[1].tips" :class="{'err_txt': datailDate[1].is_error}">
                        <template v-if="datailDate[1].is_error && datailDate[1].tips.length > 1">原因{{index +1 }}:</template> {{item}}
                    </p>
                </template>
            </div>

            <div ref="step_3" class="item" :class="{'ok': datailDate[2].is_done, 'err': datailDate[2].is_error}">
                <p class="title" :class="{'ok_txt': datailDate[2].is_done, 'err_txt': datailDate[2].is_error}">
                    {{datailDate[2].title}}
                    <button v-if="datailDate[2].is_error" @click="editInfo">再次提现</button>
                </p>
                <p class="subtitle" v-if="datailDate[2].time">{{ datailDate[2].time | format }}</p>
                <template v-for="(item, index) in datailDate[2].tips">
                    <p class="dec" v-if="datailDate[2].tips" :class="{'err_txt': datailDate[2].is_error}">
                        {{item}}
                    </p>
                </template>
            </div>

            <div class="item" v-if="datailDate.length == 4" :class="{'ok': datailDate[3].is_done, 'err': datailDate[3].is_error}">
                <p class="title" :class="{'ok_txt': datailDate[3].is_done, 'err_txt': datailDate[3].is_error}">
                    {{datailDate[3].title}}
                </p>
                <p class="subtitle"  v-if="datailDate[3].time">{{ datailDate[3].time | format }}</p>
                <template v-for="(item, index) in datailDate[3].tips">
                    <p class="dec" v-if="datailDate[3].tips" :class="{'err_txt': datailDate[3].is_error}">
                        {{item}}
                    </p>
                </template>
            </div>
            <div class="progress_left" :style="{height: progress_box}">
                <div class="inter" :style="{height: progress}"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { getDetail } from './api/index';
import { authChecker } from '@/common/js/mmcModule';
import { shareCtrl } from '@/common/js/mmcModule';
import mock from './mock'
/**
 * @params status
 * 0 默认提现成功等待处理
 * **/
export default {
    data() {
        return {
            status: 0,
            datailDate: [],
            amount: 0,
            project_uuid: '',
            project_title: '',
            progress_box: '',
            progress: '',
        }
    },
    mounted() {
        const that = this;
        authChecker.check({ notneedphone: 1 },() => {
            getDetail(that.$route.query.withdrawals_id).then(res => {
                // const res = mock
                if(res.code === 0){
                    that.datailDate = res.data.progress;
                    const { amount, project_uuid, project_title} = res.data;
                    that.amount = amount;
                    that.project_uuid = project_uuid;
                    that.project_title = project_title;
                    that.setProgress();
                    shareCtrl.init();
                }
            })
        });
    },
    filters: {
        format(time) {
            const add0 = m => m<10?'0'+m:m;
            time = new Date(time * 1000);
            let y = time.getFullYear();
            let m = time.getMonth()+1;
            let d = time.getDate();
            let h = time.getHours();
            let mm = time.getMinutes();
            let s = time.getSeconds();
            return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);

        },
    },
    methods: {
        setProgress() {
            this.$nextTick(()=>{
                let step1 = this.$refs.step_1.clientHeight;
                let step2 = this.$refs.step_2.clientHeight;
                let step3 = this.$refs.step_3.clientHeight;
                this.progress_box = this.datailDate.length > 3 ? step1 + step2 + step3 + 30 + 'px' : step1 + step2 + 20 + 'px';
                let present, ErrPreSent;
                if(this.datailDate.length <= 3) {
                    present = ['0', step1/2, step1 + step2 / 2, step1 + step2 + 20];
                    ErrPreSent = ['0',step1 +10, step1 + step2 + 20];
                }else{
                    present = ['0', step1/2, step1 + step2 / 2, step1 + step2 + step3 /2 + 20, step1 + step2 + step3 + 30];
                    ErrPreSent = ['0', step1, step1 + step2 +20, step1 + step2 + step3 + 30];
                }
                let item = 0, isErr = false;
                for(let i = 0; i < this.datailDate.length; i++){
                    if(this.datailDate[i].is_done && this.datailDate[i].is_error) {  // 有异常
                        isErr = true;
                        item = i;
                        break
                    }else if(!this.datailDate[i].is_done && !this.datailDate[i].is_error){ // 未到达位置  -1
                        item = i;
                        break
                    }else if((this.datailDate[i].is_done && !this.datailDate[i].is_error) && i + 1 === this.datailDate.length) { // 100% 完成
                        item = i + 1;
                    }
                }
                this.progress = (isErr ? ErrPreSent[item] : present[item]) + 'px';
            })
        },
        editInfo() {
            this.$router.push({
                path: `/fund/withdraw/entry`,
                query: {
                    projuuid: this.$route.query.projuuid
                }
            })
        },
    },
}
</script>
<style scoped lang="less">
    .report_box {
        padding: 35px 20px;
        width: 100%;
        overflow-x: hidden;
        h3 {
            font-size: 34px;
            text-align: left;
            margin-bottom: 10px;
        }
        .progress {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .name {
                font-size: 17px;
                display: inline-block;
                max-width: 70%;
                text-align: left;
                flex-shrink: 0;
            }
            .price {
                font-size: 17px;
                font-weight: bold;
            }
        }
        .progress_box {
            text-align: left;
            margin-top: 30px;
            padding-left: 30px;
            position: relative;
            .progress_left {
                position: absolute;
                left: 5px;
                top: 9px;
                width: 4px;
                background: #EFF2F5;
                &.hit {
                    height: 75%;
                }
                .inter {
                    width: 100%;
                    height: 25%;
                    background: #009BFF;
                }
            }
            .item {
                min-height: 70px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-top: 10px;
                &:after {
                    content: '';
                    width: 17px;
                    height: 17px;
                    background: #EFF2F5;
                    position: absolute;
                    left: -32px;
                    border-radius: 50%;
                    z-index: 1;
                }
                .title {
                    font-size: 17px;
                    font-weight: bold;
                    color: #999;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    &.ok_txt {
                        color: #009BFF;
                    }
                    &.err_txt {
                        color: #FF434B;
                    }
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
                        box-shadow: 0 2px 15px 0 rgba(0,155,255,0.40);
                        border-radius: 4px;
                        font-family: PingFangSC-Regular;
                        flex-shrink: 0;
                        font-size: 13px;
                        color: #FFFFFF;
                        padding: 8px 0;
                        width: 80px;
                        text-align: center;
                        border: none;
                    }
                }
                .subtitle {
                    font-size: 13px;
                    color: #999;
                }
                .dec {
                    font-size: 13px;
                    color: #999;
                    &.err_txt {
                        color: #FF434B;
                    }
                    &.ok_txt {
                        color: #009BFF;
                    }
                }
            }
            .ok {
                position: relative;
                &:after {
                    content: '';
                    width: 17px;
                    height: 17px;
                    background: url(./image/ok.png);
                    background-size: 100% 100%;
                    position: absolute;
                    left: -32px;
                    border-radius: 50%;
                    z-index: 1;
                }
            }
            .err {
                position: relative;
                &:after {
                    content: '';
                    width: 17px;
                    height: 17px;
                    background: url(./image/err.png);
                    background-size: 100% 100%;
                    position: absolute;
                    left: -32px;
                    border-radius: 50%;
                    z-index: 1;
                }
            }
        }
    }
</style>
