export default {
    "data":{
        "withdrawals_id":20,
        "project_uuid":"1b2d892f-df23-49ed-9e60-3de25d1bb52c",
        "project_title":"1012发起项目",
        "amount":10019100,
        "progress":[
            {
                "title":"提现申请成功",
                "time":1634107127,
                "is_done":true,
                "is_error":false,
                "tips":[

                ]
            },
            {
                "title":"提现资料审核 未审核通过",
                "time":1634107293,
                "is_done":true,
                "is_error":false,
                "tips":[
                    "fsffds发生的范德萨粉红色的粉红色点击发送放假还是返回瞬间恢复",
                    "fsffds发生的范德萨粉红色的粉红色点击发送放假还是返回瞬间恢复",
                    "fsffds发生的范德萨粉红色的粉红色点击发送放假还是返回瞬间恢复",
                ]
            },
            {
                "title":"提交至银行打款",
                "time":0,
                "is_done":true,
                "is_error":true,
                "tips":[

                ]
            }
        ]
    },
    "next":"0",
    "code":0,
    "msg":"OK",
    "total":0
}
